export const podiumService = {
    submitPasswordReset,
    checkValidationKey,
    submitNewAdminPassword
}

function submitNewAdminPassword(payload) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    };
  
    return fetch(
      window._env_.VUE_APP_API_ENDPOINT + "/Api/SubmitNewAdminPassword",
      requestOptions
          ).then(handleResponse);
  }

function checkValidationKey(payload) {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" }
    };
  
    return fetch(
      window._env_.VUE_APP_API_ENDPOINT + "/Api/CheckValidationKey/" + payload,
      requestOptions
          ).then(handleResponse);
  }

function submitPasswordReset(payload) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload)
    };
  
    return fetch(
      window._env_.VUE_APP_API_ENDPOINT + "/Api/ResetMgmtPassword",
      requestOptions
          ).then(handleResponse);
  }

  function handleResponse(response) {
    //console.log(response)
    return response.text().then(text => {
      const data = text && JSON.parse(text);
      if (!response.ok) {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
        //   logout();
        //   location.reload(true);
        }
  
        const error = (data && data.message) || response.statusText;
        return Promise.reject(error);
      }
  
      return data;
    });
  }