import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Validate from '../views/Validate.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },{
    path: '/resetpassword/:id',
    name: 'Validate',
    component: Validate
  },
  // otherwise redirect to home
  { path: "*", redirect: "/" }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
