import { podiumService } from "../services/podiumService.service";

export const podiumStore = {
    namespaced: true,
    state: {
        submitted: false,
        emailSent: false,
        validationKeyOk: false,
        validationFailed: false,
        validating: true,
        submittingNewPassword: false,
        resetComplete: false
    },
    actions: {
        submitPasswordReset({ commit }, payload) {
            commit("submitPasswordReset")

            podiumService.submitPasswordReset(payload).then(() => {
                commit("resetEmailSent")
            })
        },
        checkPasswordValidation({ commit }, payload) {
            commit("validatingPasswordResetKey")

            podiumService.checkValidationKey(payload).then(response => {
                commit("validationComplete", response)
            })
        },
        submitNewPassword({ commit }, payload) {
            commit("submittingNewPassword")

            podiumService.submitNewAdminPassword(payload).then(() => {
                commit("newPasswordSubmitted")
            })
        }
    },
    mutations: {
        submitPasswordReset(state) {
            state.submitted = true;
        },
        resetEmailSent(state) {
            state.emailSent = true;
        },
        validatingPasswordResetKey(state) {
            state.validationKeyOk = false;
            state.validationFailed = false;
            state.validating = true;
        },
        validationComplete(state, payload) {
            if (payload.validKey) {
                state.validationKeyOk = true;
                state.validationFailed = false;
                state.validating = false
            } else {
                state.validationFailed = true;
                state.validationKeyOk = false;
                state.validating = false
            }
        },
        newPasswordSubmitted(state) {
            state.submittingNewPassword = false;
            state.resetComplete = true;
        },
        submittingNewPassword(state) {
            state.resetComplete = false
            state.submittingNewPassword = true;
        }
    }
}