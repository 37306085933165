<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
      dense
    >
      <div class="d-flex align-center">

      </div>

      <v-spacer></v-spacer>

    </v-app-bar>

    <v-main>
      <v-layout flex align-center justify-center fill-height>
        <v-flex xs12 md8 lg7>
          <v-row v-if="!emailSent">
            <v-col cols="8" offset="2" lg="6" offset-lg="3">
              <v-card>
                <v-card-text class="pt-4">
                  <v-row>
                    <v-col class="text-center">
                      <img src="https://cdn2.podium365.com/clients/partner-1/podium/PodiumLogoWithText.png" style="height: 150px;" class="mt-1 mb-4"/>
                    </v-col>
                  </v-row>
                  <h2 class="mb-4 text-center">Password Reset</h2>
                  <v-form v-model="valid" ref="form" lazy-validation>
                    <!-- <h3>Login to your account</h3> -->
                        <v-text-field
                          hint="Enter your Podium365 username"
                          label="Email Address"
                          outlined
                          placeholder=" "
                          v-model="username"
                          :rules="emailRules"
                          :validate-on-blur="true"
                          required
                          :persistent-hint="true"
                          class="mt-4"
                          @keyup.enter="verifyCaptchar"
                        ></v-text-field>

                        <vue-recaptcha
                          sitekey="6Ld0Tt0aAAAAAIe9I0CE35cvrv7xUtq7XZ0xiEZH"
                          :loadRecaptchaScript="true"
                          @verify="verifyCaptchar"
                          ref="recaptcha"
                          
                        >
                          <v-btn :disabled="submitted" color="primary" block class="mt-3">
                            Submit
                            <fa-icon
                              :icon="['far', 'sign-in-alt']"
                              v-if="!submitted"
                              size="lg"
                              class="ml-2"
                            />
                            <fa-icon
                              :icon="['far', 'spinner']"
                              v-if="submitted"
                              pulse
                              size="lg"
                              class="ml-2"
                            />
                          </v-btn>
                        </vue-recaptcha>
                        
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="8" offset="2" lg="6" offset-lg="3">
              <v-row>
                    <v-col class="text-center">
                      <img src="https://cdn2.podium365.com/clients/partner-1/podium/PodiumLogoWithText.png" style="height: 150px;" class="mt-1 mb-4"/>
                    </v-col>
                  </v-row>
              <v-card>
                <v-card-text>
                  <h3>Reset Email Sent</h3>
                  <p>
                    An email containing the password reset details has been sent to: {{ username }}
                  </p>
                  <p>Please Note: the password reset email is only valid for 15 minutes from now.</p>
                  <p>This page can now be closed.</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-flex>
    </v-layout>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
  import VueRecaptcha from "vue-recaptcha";

export default {
  name: 'Home',
  methods: {
    verifyCaptchar(token) {
      if (this.$refs.form.validate()) {
        const { dispatch } = this.$store;

        let user = {
            username: this.username,
            token: token,
          };
          
          dispatch("podiumStore/submitPasswordReset", user);
      }
    },
    resetRecaptcha() {
        this.$refs.recaptcha.reset() // Direct call reset method
      }
  },
  data() {
    return {
      valid: false,
      emailRules: [
          (v) => !!v || "E-mail is required",
          (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
        ],
        username: ""
    }
  },
  components: { VueRecaptcha },
  computed: {
      ...mapState("podiumStore", ["submitted", "emailSent"])
    },
    watch: {
      submitted() {
        if (!this.submitted) {
          this.resetRecaptcha()
        }
      }
    }
}
</script>
