var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-main',[_c('v-layout',{attrs:{"flex":"","align-center":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","md8":"","lg6":""}},[(_vm.validating)?_c('div',[_vm._v(" Validating "),_c('fa-icon',{staticClass:"ml-2",attrs:{"icon":['far', 'spinner'],"pulse":"","size":"lg"}})],1):_vm._e(),(_vm.resetComplete)?_c('div',[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('img',{staticClass:"mt-1 mb-4",staticStyle:{"height":"150px"},attrs:{"src":"https://cdn2.podium365.com/clients/partner-1/podium/PodiumLogoWithText.png"}})])],1),_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6","offset-md":"3"}},[_c('v-card',[_c('v-card-text',[_c('h2',[_vm._v("Your Password Has Been Reset")]),_c('p',[_vm._v("This page can now be closed.")])])],1)],1)],1)],1):_vm._e(),(_vm.validationKeyOk && !_vm.resetComplete)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"8","offset":"2"}},[_c('v-card',[_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('img',{staticClass:"mt-1",attrs:{"src":"/PodiumSmall.png"}})])],1),_c('v-card-title',[_vm._v("Password Reset")]),_c('v-card-subtitle',[_vm._v("The password reset request has been validated."),_c('br'),_vm._v("Please enter your new password below")]),_c('v-card-text',{},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Password Strength")]),_c('ul',[_c('li',{class:{
                              is_valid: _vm.contains_eight_characters,
                            }},[_vm._v(" 8 Characters ")]),_c('li',{class:{ is_valid: _vm.contains_number }},[_vm._v(" Contains Number ")]),_c('li',{class:{ is_valid: _vm.contains_lowercase }},[_vm._v(" Contains Lowercase ")]),_c('li',{class:{ is_valid: _vm.contains_uppercase }},[_vm._v(" Contains Uppercase ")]),_c('li',{class:{
                              is_valid: _vm.contains_special_character,
                            }},[_vm._v(" Contains Special Character ")]),(_vm.isEasyToGuess)?_c('li',{staticClass:"red--text"},[_vm._v(" This password is "),_c('strong',[_vm._v("Too Easy")]),_vm._v(" to guess. ")]):_vm._e()])])],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"hint":"At least: 8 characters, one uppercase, lowercase, number and special character","persistent-hint":true,"label":"New Password","min":"8","outlined":"","placeholder":" ","rules":[
                            _vm.rules.required,
                            _vm.rules.min,
                            _vm.rules.containsNumber,
                            _vm.rules.containsUpperCase,
                            _vm.rules.containsLowerCase,
                            _vm.rules.containsSpecialCharacter,
                            _vm.rules.isEasyToGuess,
                          ],"counter":"","append-icon":_vm.pwdShow ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.pwdShow ? 'text' : 'password',"validate-on-blur":""},on:{"click:append":function($event){_vm.pwdShow = !_vm.pwdShow},"keyup":_vm.checkStrength},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"hint":"Re-Enter your new password","persistent-hint":true,"label":"Re-Enter Password","min":"8","outlined":"","placeholder":" ","rules":[_vm.rules.equal],"counter":"","validate-on-blur":"","type":"password"},model:{value:(_vm.repassword),callback:function ($$v) {_vm.repassword=$$v},expression:"repassword"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"text-right"},[_c('vue-recaptcha',{attrs:{"sitekey":"6Ld0Tt0aAAAAAIe9I0CE35cvrv7xUtq7XZ0xiEZH","loadRecaptchaScript":true},on:{"verify":_vm.resetpassword}},[_c('v-btn',{attrs:{"color":"primary"}},[_c('fa-icon',{staticClass:"mr-2",attrs:{"icon":['far', 'lock'],"size":"lg"}}),_vm._v("Submit ")],1)],1)],1)],1)],1)],1)],1)],1)],1)],1):_vm._e(),(_vm.validationFailed)?_c('div',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("Validation Failed")]),_c('p',[_vm._v(" This reset request has failed. This may be due to an invalid code, or that the code has expired after 15 minutes. ")])])],1)],1):_vm._e()])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }