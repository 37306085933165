<template>
  <v-app>
    <v-main>
      <v-layout flex align-center justify-center fill-height>
        <v-flex xs12 md8 lg6>
          <div v-if="validating">
            Validating
            <fa-icon :icon="['far', 'spinner']" pulse size="lg" class="ml-2" />
          </div>
          <div v-if="resetComplete">
            <v-row>
              <v-col class="text-center">
                <img
                  src="https://cdn2.podium365.com/clients/partner-1/podium/PodiumLogoWithText.png"
                  style="height: 150px"
                  class="mt-1 mb-4"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6" offset-md="3">
                <v-card>
                  <v-card-text>
                    <h2>Your Password Has Been Reset</h2>
                    <p>This page can now be closed.</p>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
          <div v-if="validationKeyOk && !resetComplete">
            <v-row>
              <v-col cols="8" offset="2">
                <v-card>
                  <v-row>
                    <v-col class="text-center">
                      <img src="/PodiumSmall.png" class="mt-1" />
                    </v-col>
                  </v-row>
                  <v-card-title>Password Reset</v-card-title>
                  <v-card-subtitle
                    >The password reset request has been validated.<br />Please
                    enter your new password below</v-card-subtitle
                  >
                  <v-card-text class="">
                    <v-form v-model="valid" ref="form" lazy-validation>
                      <v-row>
                        <v-col>
                          <h3>Password Strength</h3>
                          <ul>
                            <li
                              v-bind:class="{
                                is_valid: contains_eight_characters,
                              }"
                            >
                              8 Characters
                            </li>
                            <li v-bind:class="{ is_valid: contains_number }">
                              Contains Number
                            </li>
                            <li v-bind:class="{ is_valid: contains_lowercase }">
                              Contains Lowercase
                            </li>
                            <li v-bind:class="{ is_valid: contains_uppercase }">
                              Contains Uppercase
                            </li>
                            <li
                              v-bind:class="{
                                is_valid: contains_special_character,
                              }"
                            >
                              Contains Special Character
                            </li>
                            <li class="red--text" v-if="isEasyToGuess">
                              This password is <strong>Too Easy</strong> to
                              guess.
                            </li>
                          </ul>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            hint="At least: 8 characters, one uppercase, lowercase, number and special character"
                            :persistent-hint="true"
                            label="New Password"
                            v-model="password"
                            min="8"
                            outlined
                            placeholder=" "
                            :rules="[
                              rules.required,
                              rules.min,
                              rules.containsNumber,
                              rules.containsUpperCase,
                              rules.containsLowerCase,
                              rules.containsSpecialCharacter,
                              rules.isEasyToGuess,
                            ]"
                            counter
                            :append-icon="pwdShow ? 'mdi-eye' : 'mdi-eye-off'"
                            :type="pwdShow ? 'text' : 'password'"
                            @click:append="pwdShow = !pwdShow"
                            @keyup="checkStrength"
                            validate-on-blur
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-text-field
                            hint="Re-Enter your new password"
                            :persistent-hint="true"
                            label="Re-Enter Password"
                            v-model="repassword"
                            min="8"
                            outlined
                            placeholder=" "
                            :rules="[rules.equal]"
                            counter
                            validate-on-blur
                            type="password"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="text-right">
                          <vue-recaptcha
                            sitekey="6Ld0Tt0aAAAAAIe9I0CE35cvrv7xUtq7XZ0xiEZH"
                            :loadRecaptchaScript="true"
                            @verify="resetpassword"
                          >
                            <v-btn color="primary">
                              <fa-icon
                                :icon="['far', 'lock']"
                                size="lg"
                                class="mr-2"
                              />Submit
                            </v-btn>
                          </vue-recaptcha>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>

          <div v-if="validationFailed">
            <v-row>
              <v-col>
                <h2>Validation Failed</h2>
                <p>
                  This reset request has failed. This may be due to an invalid
                  code, or that the code has expired after 15 minutes.
                </p>
              </v-col>
            </v-row>
          </div>
        </v-flex>
      </v-layout>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
import VueRecaptcha from "vue-recaptcha";
import pwds from "@/assets/easyPasswords.json";
import _ from "lodash";

export default {
  name: "Validate",
  data() {
    return {
      validationKey: this.$route.params.id,
      valid: false,
      password: "",
      pwdShow: false,
      password_length: 0,
      contains_eight_characters: false,
      contains_number: false,
      contains_lowercase: false,
      contains_uppercase: false,
      contains_special_character: false,
      isEasyToGuess: false,
      repassword: "",
      pwdList: pwds.passowrds,
      rules: {
        required: (value) => !!value || "Password Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        equal: (v) => v === this.password || "Passwords do not match",
        containsNumber: (v) =>
          /\d/.test(v) ||
          "Min. 8 characters with at least one lower and upper case character, a number and a special character. !@#$£%^&*()_+-=[]{};':\"\\|,.<>/?",
        containsUpperCase: (v) =>
          /[A-Z]/.test(v) ||
          "Min. 8 characters with at least one lower and upper case character, a number and a special character. !@#$£%^&*()_+-=[]{};':\"\\|,.<>/?",
        containsLowerCase: (v) =>
          /[a-z]/.test(v) ||
          "Min. 8 characters with at least one lower and upper case character, a number and a special character. !@#$£%^&*()_+-=[]{};':\"\\|,.<>/?",
        containsSpecialCharacter: (value) => {
          const format = /[ !@#$£%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
          return (
            format.test(value) ||
            "Min. 8 characters with at least one lower and upper case character, a number and a special character. !@#$£%^&*()_+-=[]{};':\"\\|,.<>/?"
          );
        },
        isEasyToGuess: (value) => {
          let easy = true;

          if (value.length > 6) {
            let word = pwds.passwords.find(
              (a) =>
                value.toLowerCase().includes(a) ||
                value.toLowerCase().includes("password") ||
                value.toLowerCase().includes("qwerty") ||
                value.toLowerCase().includes("1234") ||
                value.toLowerCase().includes("1111") ||
                value.toLowerCase().includes("9999") ||
                value.toLowerCase().includes("xxx")
            );
            //console.log(word);
            if (word !== null && !_.isUndefined(word)) {
              easy = false;
            }
          }

          return (
            easy ||
            "Too Easy: This password contains words or numbers in a commonly used sequence and could therefore be easy to guess."
          );
        },
      },
    };
  },
  components: { VueRecaptcha },
  computed: {
    ...mapState("podiumStore", [
      "resetComplete",
      "validating",
      "validationKeyOk",
      "validationFailed",
      "passwordTooEasy",
    ]),
  },
  methods: {
    resetpassword(token) {
      //console.log(this.$refs.form.validate());

      if (this.$refs.form.validate()) {
        let obj = {
          password: this.password
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;"),
          token: token,
          validationKey: this.$route.params.id,
        };
        //console.log(obj);
        const { dispatch } = this.$store;
        dispatch("podiumStore/submitNewPassword", obj);
      }
    },
    checkStrength() {
      const format = /[ !@#$£%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password.length >= 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password);
      this.contains_lowercase = /[a-z]/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      this.isEasyToGuess = false;

      if (this.password.length >= 6) {
        let word = pwds.passwords.find(
          (a) =>
            this.password.toLowerCase().includes(a) ||
            this.password.toLowerCase().includes("password") ||
            this.password.toLowerCase().includes("qwerty") ||
            this.password.toLowerCase().includes("1234") ||
            this.password.toLowerCase().includes("1111") ||
            this.password.toLowerCase().includes("9999") ||
            this.password.toLowerCase().includes("xxx")
        );
        //console.log(word);
        if (word !== null && !_.isUndefined(word)) {
          this.isEasyToGuess = true;
        }
      }
    },
  },
  mounted() {
    const { dispatch } = this.$store;
    dispatch("podiumStore/checkPasswordValidation", this.validationKey);
  },
};
</script>

<style>
ul {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

li {
  margin-bottom: 8px;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.is_valid {
  color: rgba(136, 152, 170, 0.8);
}
.is_valid:before {
  width: 100%;
}

.checkmark_container {
  border-radius: 50%;
  position: absolute;
  top: -15px;
  right: -15px;
  background: #2ecc71;
  width: 50px;
  height: 50px;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.4s ease;
}

.show_checkmark {
  visibility: visible;
  opacity: 1;
}

.checkmark {
  width: 100%;
  height: 100%;
  fill: none;
  stroke: white;
  stroke-width: 15;
  stroke-linecap: round;
  stroke-dasharray: 180;
  stroke-dashoffset: 180;
}

.checked {
  animation: draw 0.5s ease forwards;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
